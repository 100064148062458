<template>
  <div class="container">
    <pcPublicHeader />
    <div class="public-content">
      <div class="page-title-item">个人中心</div>
      <div class="user-container">
        <pcUserHeader pageKey="collection" />
        <div class="goods-container">
          <div v-if="goodsList.length == 0 && finish && !loading" class="empty-item">
            <div class="img img-cover">
              <img src="../../assets/pc-images/empty-img.png" />
            </div>
          </div>
          <div v-else class="goods-list">
            <router-link :to="{path: '/goodsDetail', query: {id: item.GoodsId}}" class="goods-item" v-for="(item, index) in goodsList" :key="index">
              <div class="img-item img-cover">
                <div class="del-btn img-cover" @click.prevent="handleDelete(item)"><img src="../../assets/pc-images/delete-icon.png" /></div>
                <img v-if="item.GoodsThumb" :src="item.GoodsThumb" />
                <img v-else src="../../assets/images/no-image-icon.png" />
              </div>
              <div class="detail-item">
                <div class="name">
                  <span class="tag" v-if="item.Lable">{{ item.Lable }}</span>
                  <span class="word">{{ item.GoodsName }}</span>
                </div>
                <div class="money">{{ parseFloat(item.GoodsPrice).toFixed(4) }}</div>
              </div>
            </router-link>
          </div>
          <div v-if="total > pageCount" class="pagination-item">
            <el-pagination background layout="prev, pager, next" :page-size="pageCount" :total="total" @current-change="handleCurrentPageChange"></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import pcPublicHeader from '@/components/pcPublicHeader'
import pcUserHeader from '@/components/pcUserHeader'

export default {
  name: "Collection",
  components: {
    pcPublicHeader,
    pcUserHeader
  },
  data() {
    return {
      goodsList: [],
      pageCount: 21,
      page: 1,
      total: 0,
      finish: false,
      loading: false
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    phoneFormat(text) {
      if (text) {
        return text.substr(0, 3) + '****' + text.substr(-4)
      }
      return ''
    }
  },
  mounted() {
    this.getGoodsList()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleCurrentPageChange(val) {
      this.page = val
      this.getGoodsList()
    },
    async getGoodsList() {
      const { loading, pageCount, page } = this
      if (loading) {
        return
      }
      const params = {
        PageCount: pageCount, 
        Page: page,
      }
      var res = await this.$API.goodsCollectList.post(params)
      if (res.info && res.info.length > 0) {
        for (let index = 0; index < res.info.length; index++) {
          const item = res.info[index]
          res.info[index].Bq = item.Bq ? item.Bq.split(',') : []
        }
      }
      this.goodsList = [
        ...res.info
      ]
      this.total = res.count
      this.finish = res.info.length < pageCount
      this.loading = false
    },
    handleDelete(item) {
      this.$dialog.confirm({
        type: 'info',
        className: 'pc-dialog pc-dialog-delete',
        title: '确认删除',
        message: '您确认要删除此宝贝吗？删除之后不可恢复',
        cancelButtonText: '取消',
        confirmButtonText: '删除',
      }).then(async () => {
        var res = await this.$API.addGoodsCollect.post({
          GoodsId: item.GoodsId
        })
        if (res.error == 0) {
          this.$toast.success(res.info)
          this.getGoodsList()
        } else {
          this.$dialog.alert({
            type: 'danger',
            className: 'pc-dialog',
            title: '提示',
            message: res.info,
            confirmButtonText: '确定',
          })
        }
      }).catch(() => {
      })
    },
  }
};
</script>

<style lang="less" scoped>
.page-title-item {
  padding: 30px 0 24px;
  line-height: 46px;
  color: #3d3d42;
  font-size: 32px;
  font-weight: bold;
}
.user-container {
  background-color: #fafbff;
  border-radius: 8px;
  overflow: hidden;
}
.goods-container {
  .goods-list {
    padding: 30px 20px;
    background-color: #fff;
    overflow: hidden;
    .goods-item {
      display: block;
      float: left;
      width: 220px;
      height: 296px;
      padding: 10px;
      border-radius: 12px;
      &:hover {
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, .08);
        .img-item {
          .del-btn {
            display: block;
          }
        }
      }
      .img-item {
        position: relative;
        width: 220px;
        height: 220px;
        border-radius: 12px 12px 0 0;
        overflow: hidden;
        .del-btn {
          display: none;
          width: 24px;
          height: 24px;
          padding: 10px;
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 0 12px;
          background-color: rgba(0, 0, 0, .6);
        }
      }
      .detail-item {
        padding: 10px 12px;
        .name {
          height: 18px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: 18px;
          font-size: 14px;
          color: #000;
          .tag {
            margin-right: 6px;
            display: block;
            float: left;
            padding: 0 8px;
            background: linear-gradient( 90deg, #6a5ffe 0%, #21e8fe 100%);
            height: 18px;
            line-height: 18px;
            font-size: 10px;
            color: #fff;
          }
        }
        .money {
          margin-top: 10px;
          line-height: 24px;
          font-size: 20px;
          color: #f00;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
